import {Container,Row,Col,Accordion,Card,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Pageart from "../components/pageart/pageart"
import AccordionContent from "../components/accordionContent/accordionContent"
import ModalOverlay from "../components/modal/modal.js"
import Share from "../components/share/share.js"
import {Link} from "gatsby"
import Oatmeal from "../images/heart-healthy/oatmeal.jpg"
import Eggs from "../images/heart-healthy/eggs.jpg"
import Apple from "../images/apple.svg"

const HeartHealthyPage = () => ( 

  <Layout>

    <SEO title="Heart-Healthy Habits" 
      description="Are you doing enough to protect yourself from a heart attack or stroke? Get helpful tips, including healthier holiday recipes."
    />

    <div className="heart-healthy">

      <Pageart content="Heart-Healthy Habits:" content2="Helpful Tips"></Pageart>

      <AccordionContent />

      <Container fluid className="page-container">
        <Row>
          <Col className="content-wrapper">

            <div className="mt-0 mb-0">
              <p className="pb-3 pr-3">It&rsquo;s something you might not always think about, but it&rsquo;s important to talk about cardiovascular (CV) health with your family—you want to make sure you&rsquo;re doing everything you can to protect yourself and your loved ones from a heart attack or stroke.</p>
              
              <p className="pr-3"><strong>Did you know that having a heart attack or stroke makes you more likely to have another?<sup className='un-bold'>1,2</sup></strong> That&rsquo;s why it’s so important to reduce the risk. Statins may not be enough to fully protect you from a serious heart attack or stroke.<sup>3</sup>  Talk to your doctor now about FDA-approved medications for CV risk reduction.</p>

              <p className="text-center pt-3 pb-3">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com",<br/>,<p className="mobile-text-300">You will be directed to another Amarin website to learn about a prescription cardiovascular treatment.</p>]}
                buttontext = "Continue"
                link = "https://vascepa.com/"
                ><span class="btn btn-primary text-decoration-none">LEARN ABOUT A PRESCRIPTION TREATMENT NOW</span></ModalOverlay></p>                
            </div>
            <div className="pb-5">
              <p className="callout border-bottom-0 mb-0"><span class="fs-40 lh-1i"><strong>Heart-healthy habits like diet and exercise may also be a big part of managing your CV risk</strong></span></p>              

              <p>Recently, Dr. Preston Mason, a cardiovascular researcher, and world-renowned Chef Millie Peartree teamed up to talk about cardiovascular health and healthier recipes on the nationwide “Heart-Healthy Holiday Hacks” Media Tour. See the recipes below, and talk to your doctor about how to maintain a heart-healthy diet.</p>

              <p className="callout border-top-0 border-bottom-0 mt-0 mb-0">Just in time for the holidays—soul food with a lot of heart</p>

              <div className="grid-with-img">
                <img src={Apple} alt="icon of apple with tape measure" />
                <p className="loud fs-24 text-default font-color-black">
                Check out two of Chef Millie Peartree’s delicious recipes where she has <strong className="font-color-secondary">substituted ingredients</strong> to make holiday favorites <span class="text-nowrap">more healthy.</span>
                </p>
              </div>

            </div>

            <div className="text-right">
              <span className="accordion-action-all expand-all">Expand all</span>
            </div>
            <hr style={{borderColor: '#ccc'}}/>

            <span id="ttyh-sweet-potato-pie-recipe" style={{"position": "absolute", "margin-top": "-100px"}}></span>

            <Accordion>

              <Card>
                <Card.Header>
                  <Accordion.Toggle variant="link" className="accordion-link" eventKey="0">
                  Oatmeal Streusel-topped Crustless Sweet&nbsp;Potato&nbsp;Pie                  
                  </Accordion.Toggle>
                  <div className="recipe mt-5">
                    <div className="prep">
                      <p>
                        <strong>PREP:</strong> 15 mins<br />
                        <strong>COOK:</strong> 40 mins<br />
                        <strong>REST:</strong> 15 mins<br />
                        <strong>READY:</strong> 1 hr 10 mins<br />
                        <strong>SERVES:</strong> 6-8
                      </p>
                    </div>
                    <div className="share-recipe">
                      <img src={Oatmeal} alt="sweet potato pie" />
                      <Share 
                      shareId="Pie"
                      twitterShare="https://twitter.com/intent/tweet?url=https://truetoyourheart.com/heart-healthy-habits/%23ttyh-sweet-potato-pie-recipe&text="
                      pinterestShare="https://pinterest.com/pin/create/button/?url=https://truetoyourheart.com/heart-healthy-habits/%23ttyh-sweet-potato-pie-recipe&media=&description="
                      recipeLink="/pdf/TTYH-Sweet-Potato-Pie-Recipe-Sheet.pdf"
                      printLink="../pdf/TTYH-Sweet-Potato-Pie-Recipe-Sheet.pdf"
                      />
                    </div>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="recipe-parts">
                      <div className="ingredients">
                        <h4>Ingredients:</h4>
                        <p>For the potatoes:</p>
                        <ul className="list list-default">
                          <li>15-ounce canned puréed sweet potato or 2 cups peeled and cooked mashed sweet potato</li>
                          <li>2/3 cup pure maple syrup</li>
                          <li>1/2 cup milk <strong>(SWAP with unsweetened almond milk)</strong></li>
                          <li>1 Tbsp vanilla extract</li>
                          <li>4 large eggs <strong>(room temp)</strong></li>
                          <li>2 Tbsp melted unsalted butter <strong>(SWAP with soft margarine)</strong></li>
                          <li>2 tsp cinnamon</li>
                          <li>1/4 tsp nutmeg</li>
                          <li>Pinch of allspice</li>
                          <li>1/4 tsp salt</li>
                          <li>3 Tbsp coconut flour</li>
                          <li>1/2 heaping cup of tapioca flour</li>
                          <li>2 tsp baking powder</li>
                        </ul>
                      </div>
                      <div className="toppings m-t-35 mobile-m-t-0">
                        <p>Topping:</p>
                          <ul className="list list-default">
                            <li>1/4 cup whole wheat pastry flour or regular flour <strong>(if GF, use gluten-free oat flour)</strong></li>
                            <li>1/3 cup rolled oats <strong>(gluten-free if desired)</strong></li>
                            <li>1/3 cup packed brown sugar <strong>(SWAP with Stevia or monkfruit sugar)</strong></li>
                            <li>1/2 cup coarsely chopped pecans</li>
                            <li>3 Tbsp melted butter <strong>(SWAP with soft margarine)</strong></li>
                          </ul>
                      </div>
                    </div>
                    <div className="recipe-instructions">
                      <h4>Instructions:</h4>
                      <h3 className="text-default">Preheat oven to 350° F. Line a pie pan or cake tin with parchment paper. Set aside.</h3>
                      <ol className="number-list">
                        <li>Using a hand mixer or blender, combine your milk, eggs, maple syrup, vanilla, and butter. Add the sweet potato mash and mix again until smooth. You may also mix by hand if you’re using canned sweet potato purée.</li>
                        <li>In another small bowl, sift together the coconut flour and tapioca flour. Whisk in the spices, salt, baking powder.</li>
                        <li>Gently add the dry batter with the wet batter. Mix until smooth.</li>
                        <li>To make the topping: Whisk together flour, oats, brown sugar, and pecans. Use a fork to stir in melted butter until a nice crumb forms. Sprinkle all over the top of the sweet potato mixture.</li>
                        <li>Pour into your prepared pie or cake pan and sprinkle your crumble on top.</li>
                        <li>Bake for 40 minutes. After 40 minutes, check for doneness with toothpick. If the middle doesn’t come out clean with toothpick, then rotate the pie pan, cover with foil, and bake for an additional 15-20 minutes. The edges will be crispy brown.</li>
                        <li>Remove from oven and let cool for 15 minutes before serving. The inside of the pie will have more of a pumpkin pie/custard-like texture once cooled.</li>
                        <li>Slice pie and add a dollop of your favorite fat-free/reduced-fat topping (optional).</li>
                      </ol>
                    </div>
                    <p className="download-recipe"><a class="btn btn-primary" target="_blank" href="../pdf/TTYH-Sweet-Potato-Pie-Recipe-Sheet.pdf" download>Download the recipe</a></p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>          

            <span id="ttyh-deviled-eggs-recipe" style={{"position": "absolute", "margin-top": "-100px"}}></span>

            <Accordion>

              <Card className='last-card'>
                <Card.Header>
                  <Accordion.Toggle variant="link" eventKey="3" className="accordion-link">
                  Deviled Eggs                  
                  </Accordion.Toggle>
                  <div className="recipe">
                    <div className="prep">
                      <p>
                        <strong>PREP:</strong> 5 mins<br />
                        <strong>COOK:</strong> 10 mins<br />
                        <strong>READY:</strong> 15 mins<br />
                        <strong>SERVES:</strong> 12
                      </p>
                    </div>
                    <div className="share-recipe">
                      <img src={Eggs} alt="deviled eggs" />
                      <Share
                        shareId="Eggs"
                        twitterShare="https://twitter.com/intent/tweet?url=http://truetoyourheart.com/heart-healthy-habits/%23ttyh-deviled-eggs-recipe&text="
                        pinterestShare="https://www.pinterest.com/pin/create/button/?url=http://truetoyourheart.com/heart-healthy-habits/%23ttyh-deviled-eggs-recipe&media=&description="
                        recipeLink="/pdf/TTYH-Deviled-Eggs-Recipe-Sheet.pdf"
                        printLink="../pdf/TTYH-Deviled-Eggs-Recipe-Sheet.pdf"
                      />                      
                    </div>
                  </div>                  
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>          
                  <div className="recipe-parts deviled-eggs">
                      <div className="ingredients">
                        <h4>Ingredients:</h4>
                        <ul className="list list-default">
                          <li>6 large eggs</li>
                          <li>3 Tbsp mayonnaise <strong>(SWAP with fat-free, plain Greek yogurt)</strong></li>
                          <li>1 tsp dijon mustard</li>
                          <li>1 tsp warm water (optional)</li>
                          <li>Dash of hot sauce</li>
                          <li>1/4 tsp onion powder</li>
                          <li>Fresh chives</li>
                          <li>Paprika</li>                          
                        </ul>
                      </div>                      
                    </div>
                    <div className="recipe-instructions">
                      <h4>Instructions:</h4>
                      <ol className="number-list">
                        <li><strong className="instruct-strong">Boil:</strong> Add eggs into a large saucepan and add cold water so that water covers eggs by about 2 inches. Cover and bring water to a boil over high heat. Once boiling, remove pot from heat and let set for 10 minutes.</li>
                        <li><strong className="instruct-strong">Ice bath:</strong> While eggs sit, fill a large bowl with cold water and ice cubes. When eggs have reached 10 minutes, remove them from the hot water and drop them into the ice bath until cooled completely.</li>
                        <li><strong className="instruct-strong">Prep eggs:</strong> Gently tap eggs on the counter all around the shell to break them, then peel off shell. Cut eggs in half, lengthwise, then use a small spoon to gently scoop out the yolk.</li>
                        <li><strong className="instruct-strong">Filling:</strong> Mash yolks with a fork or potato masher, then stir in yogurt, mustard, water (depending on consistency), chives, and onion powder. If filling doesn’t seem creamy or silky smooth, add a spoonful more of yogurt (or mayonnaise).</li>
                        <li><strong className="instruct-strong">Pipe:</strong> Spoon yolk filling into a pastry bag fitted with a large star tip (alternatively, use a Ziploc bag with a corner cut off or simply spoon it into the egg whites). Pipe filling into each egg white, garnishing with paprika and chives. Serve immediately, or store for up to 8 hours in the fridge.</li>
                      </ol>
                    </div>
                    <p className="download-recipe"><a class="btn btn-primary" target="_blank" href="../pdf/TTYH-Deviled-Eggs-Recipe-Sheet.pdf" download>Download the recipe</a></p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>
            


          </Col>
        </Row>
        <Row>
          <Col>
          <p className='reference-text'>
                <strong>References: 1. </strong>American Heart Association. Life after a heart attack. https://www.heart.org/en/health-topics/heart-attack/life-after-a-heart-attack. Accessed March 8, 2022.
                <strong> 2. </strong>American Stroke Association. Stroke risk factors not within your control. https://www.stroke.org/en/about-stroke/stroke-risk-factors/stroke-risk-factors-not-within-your-control. Accessed March 8, 2022.
                <strong> 3. </strong>Ganda OP, Bhatt DL, Mason RP, Miller M, Boden WE. Unmet need for adjunctive dyslipidemia therapy in hypertriglyceridemia management. <em>J Am Coll Cardiol</em>. 2018;72(3):330-343.
              </p>
          </Col>
        </Row>
      </Container>

    </div>

  </Layout>

)

export default HeartHealthyPage