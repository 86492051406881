import React from "react"
import "./accordionContent.scss"
import accordionState from "./accordionState.js"

export default class accordionContent extends React.Component {

  componentDidMount() {
  	accordionState();
  }

  render(){

    return (

    	// Content for the accordion page to be brought here once code is refactored.
    	<span className="accordionState"></span>

    )
  }
}