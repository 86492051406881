export default function accordionState() {


	function countEls(el) {
		var elements = document.getElementsByClassName(el);
		return elements.length
	}


	function accordionSelected() {
		this.classList.toggle("on");

		var el_class = "accordion-link on";
		var opened = countEls(el_class);
		var accordion_count = document.getElementsByClassName(el_class).length;

		console.log("Opened: " + opened);

		if ( opened === 0) {
			// Expand all
			document.getElementsByClassName("accordion-action-all")[0].innerHTML = "Expand all";
			document.getElementsByClassName("accordion-action-all")[0].classList.add("expand-all");
			document.getElementsByClassName("accordion-action-all")[0].classList.remove("collapse-all");
		} else if ( opened === 4 ) { // make more dynamic, not hardcoded
			
			// Collapse all
			document.getElementsByClassName("accordion-action-all")[0].innerHTML = "Collapse all";
			document.getElementsByClassName("accordion-action-all")[0].classList.add("collapse-all");
			document.getElementsByClassName("accordion-action-all")[0].classList.remove("expand-all");
		}

		console.log( countEls("accordion-link on", accordion_count));
	}


	function accordionAllAction() {

		var all_action_is_expand = document.getElementsByClassName("accordion-action-all")[0].classList.contains("expand-all");

		for (var i = 0; i < document.getElementsByClassName("accordion-link").length; i++) {

			var this_opened = document.getElementsByClassName("accordion-link")[i].classList.contains("on");

			if (all_action_is_expand === true && this_opened === false) {
				document.getElementsByClassName("accordion-link")[i].click();
				document.getElementsByClassName("accordion-action-all")[0].classList.remove("expand-all");
				document.getElementsByClassName("accordion-action-all")[0].innerHTML = "Collapse all";
			} 

			if (all_action_is_expand === false && this_opened === true) {
				document.getElementsByClassName("accordion-link")[i].click();
				document.getElementsByClassName("accordion-action-all")[0].classList.add("expand-all");
				document.getElementsByClassName("accordion-action-all")[0].innerHTML = "Expand all";
			}

			console.log(all_action_is_expand, this_opened);
		    
		}

	}



	var accordionItem = document.getElementsByClassName("accordion-link");

	for (var i = 0; i < accordionItem.length; i++) {
	    accordionItem[i].addEventListener('click', accordionSelected, false);
	}




	document.getElementsByClassName("accordion-action-all")[0].addEventListener('click', accordionAllAction, false)


}