import React, { useState } from "react"
import { useLocation } from '@reach/router';

import classNames from 'classnames';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import "./share.scss"
import TwitterIcon from "../../images/heart-healthy/twitter.svg"
import PinterestIcon from "../../images/heart-healthy/pinterest.svg"
import LinkIcon from "../../images/heart-healthy/link.svg"
import PrintIcon from "../../images/heart-healthy/print.svg"

function Share(props) {
    const [copied, setCopied] = useState(false);
    const location = useLocation();


    const copyInClipboard = () => {
        setCopied(true);
        setTimeout(() => {setCopied(false)}, 1500);
    }

    return (
        <div className="share">
            <h5>Share:</h5>
            <a href={props.twitterShare} className="twitter" title="Share the recipe on Twitter" target="_blank">
                <img id={props.shareId+"Twitter"} src={TwitterIcon} alt="twitter icon" />
            </a>
            <a href={props.pinterestShare} className="pinterest" title="Share the recipe on Pinterest" target="_blank">
                <img id={props.shareId+"Pinterest"} src={PinterestIcon} alt="pinterest icon" />
            </a>
            <div className="link copy-link">
                <CopyToClipboard text={location.origin + props.recipeLink} onCopy={copyInClipboard}>
                    <img id={props.shareId+"LinkCopy"} src={LinkIcon} alt="link icon" />
                </CopyToClipboard>
                <div className={classNames('copied-popup', {'show': copied})}>Copied</div>
            </div>
            <a href={props.printLink} className="print" target="_blank" title="Print this recipe">
                <img id={props.shareId+"Print"} src={PrintIcon} alt="print icon" />
            </a>
            
        </div>
    );
}

export default Share

